<template>
  <div class="userList">
    <searchBar :formItems="formItems" @search="search" />
    <div style="margin-bottom: 10px" v-if="power === '1'">
      <el-button type="primary" @click="add()">
        <i class="el-icon-plus" />新增
      </el-button>
    </div>
    <div class="tableBox">
      <el-table
        :data="tableData"
        :cell-style="{ textAlign: 'center' }"
        :header-cell-style="{ textAlign: 'center' }"
        border
        style="width: 100%"
      >
        <el-table-column
          prop="mahjongNumber"
          label="麻将桌号"
          textAlign="left"
          width="150"
        ></el-table-column>
        <el-table-column prop="detail" label="二维码（上下左右）">
          <template slot-scope="scope">
            <div class="codeBox">
              <div class="code">
                <vue-qr
                  ref="qrCode"
                  :text="scope.row.id + '-' + scope.row.belong + '-up'"
                  color-dark="#000"
                  width="200"
                  height="200"
                ></vue-qr>
              </div>
              <div class="code">
                <vue-qr
                  ref="qrCode"
                  :text="scope.row.id + '-' + scope.row.belong + '-down'"
                  color-dark="#000"
                  width="200"
                  height="200"
                ></vue-qr>
              </div>
              <div class="code">
                <vue-qr
                  ref="qrCode"
                  :text="scope.row.id + '-' + scope.row.belong + '-left'"
                  color-dark="#000"
                  width="200"
                  height="200"
                ></vue-qr>
              </div>
              <div class="code">
                <vue-qr
                  ref="qrCode"
                  :text="scope.row.id + '-' + scope.row.belong + '-right'"
                  color-dark="#000"
                  width="200"
                  height="200"
                ></vue-qr>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="nickName" label="麻将馆名称" textAlign="left">
          <template slot-scope="scope">
            {{ mahjongPosition(scope.row.belong) }}
          </template>
        </el-table-column>
        <el-table-column prop="upPosition" label="上位" width="150">
          <template slot-scope="scope">
            <div
              style="display: flex; align-items: center"
              v-if="scope.row.upUserInfo"
            >
              <img
                style="width: 50px; height: 50px; border-radius: 100%"
                :src="
                  scope.row.upUserInfo
                    ? scope.row.upUserInfo.headUrl
                    : require('@/assets/images/1.png')
                "
              />
              <span style="margin-left: 5px">{{
                scope.row.upUserInfo ? scope.row.upUserInfo.nickName : ""
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="downPosition" label="下位">
          <template slot-scope="scope">
            <div
              style="display: flex; align-items: center"
              v-if="scope.row.downUserInfo"
            >
              <img
                style="width: 50px; height: 50px; border-radius: 100%"
                :src="
                  scope.row.downUserInfo
                    ? scope.row.downUserInfo.headUrl
                    : require('@/assets/images/1.png')
                "
              />
              <span style="margin-left: 5px">{{
                scope.row.downUserInfo ? scope.row.downUserInfo.nickName : ""
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="leftPosition" label="左位">
          <template slot-scope="scope">
            <div
              style="display: flex; align-items: center"
              v-if="scope.row.leftUserInfo"
            >
              <img
                style="width: 50px; height: 50px; border-radius: 100%"
                :src="
                  scope.row.leftUserInfo
                    ? scope.row.leftUserInfo.headUrl
                    : require('@/assets/images/1.png')
                "
              />
              <span style="margin-left: 5px">{{
                scope.row.leftUserInfo ? scope.row.leftUserInfo.nickName : ""
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="rightPosition" label="右位">
          <template slot-scope="scope">
            <div
              style="display: flex; align-items: center"
              v-if="scope.row.rightUserInfo"
            >
              <img
                style="width: 50px; height: 50px; border-radius: 100%"
                :src="
                  scope.row.rightUserInfo
                    ? scope.row.rightUserInfo.headUrl
                    : require('@/assets/images/1.png')
                "
              />
              <span style="margin-left: 5px">{{
                scope.row.rightUserInfo ? scope.row.rightUserInfo.nickName : ""
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="detail" label="状态">
          <template slot-scope="scope">
            <span
              :class="
                scope.row.status === '0'
                  ? 'c_grey'
                  : scope.row.status === '1'
                  ? 'c_green'
                  : ''
              "
            >
              {{
                scope.row.status === "0"
                  ? "空闲"
                  : scope.row.status === "1"
                  ? "进行中"
                  : ""
              }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="emptyTable(scope.row)"
              >清台</el-button
            >
            <el-button
              v-if="scope.row.status === '0'"
              type="text"
              size="small"
              @click="beginTable(scope.row)"
              >开始</el-button
            >
            <el-button
              v-if="scope.row.status === '1'"
              type="text"
              size="small"
              @click="endTable(scope.row)"
              >结算</el-button
            >
            <el-button
              v-if="power === '1'"
              type="text"
              size="small"
              @click="editData(scope.row)"
              >编辑</el-button
            >
            <el-button
              v-if="power === '1'"
              @click="deleteData(scope.row)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fenye">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      ></el-pagination>
    </div>
    <ActivityDialog ref="dialog" @search="initPage" />
    <InsureDialog ref="dialog2" @search="initPage" />
  </div>
</template>

<script>
import VueQr from "vue-qr";
import searchBar from "@/components/SearchBar/index";
import ActivityDialog from "./components/dialog.vue";
import InsureDialog from "./components/insureDialog.vue";
export default {
  components: { searchBar, ActivityDialog, VueQr, InsureDialog },
  data() {
    return {
      timer: null,
      power: "2",
      page: {
        page: 1,
        pageSize: 10,
      },
      params: {
        belong: "1",
      },
      total: 0,
      tableData: [],
      mahjongList: [
        {
          label: "菜秘书麻将馆-高攀路店",
          value: "1",
        },
        {
          label: "菜秘书麻将馆-川大店",
          value: "2",
        },
      ],
      formItems: [
        {
          label: "",
          type: "el-input",
          fieldName: "number",
          attrs: {
            placeholder: "麻将桌号",
          },
        },
        {
          label: "",
          type: "el-select",
          fieldName: "belong",
          attrs: {
            placeholder: "店铺名称",
          },
          defaultValue: "1",
          data: [
            {
              label: "菜秘书麻将馆-高攀路店",
              value: "1",
            },
            {
              label: "菜秘书麻将馆-川大店",
              value: "2",
            },
          ],
        },
      ],
    };
  },
  methods: {
    emptyTable(item) {
      //清空座位
      this.$confirm("确定清空座位吗？").then(() => {
        this.getAjax("api/mahjong/emptyTable", { id: item.id }, "post").then(
          (res) => {
            this.initPage();
            this.message("操作成功");
          }
        );
      });
    },
    beginTable(item) {
      //开始
      this.$confirm("确定开始吗？").then(() => {
        this.getAjax(
          "api/mahjong/setStatusGoing",
          { id: item.id },
          "post"
        ).then((res) => {
          this.initPage();
          this.message("操作成功");
        });
      });
    },
    endTable(item) {
      this.$refs.dialog2.getInfo(item);
      // 打开结算
    },
    mahjongPosition(position) {
      const obj = this.mahjongList.find((e) => e.value === position);
      if (obj) {
        return obj.label;
      }
    },
    changeEnable(enable, id) {
      const data = enable === "0" ? "1" : "0";
      this.getAjax("api/activity/enable", { enable: data, id }, "post").then(
        (res) => {
          this.initPage();
          this.message("操作成功");
        }
      );
    },
    indexMethod(index) {
      return index + 1 + (this.page.page - 1) * this.page.pageSize;
    },
    handleSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.initPage();
    },
    handleCurrentChange(page) {
      this.page.page = page;
      this.initPage();
    },
    search(opt) {
      //搜索
      this.page.page = 1;
      this.params = opt;
      this.initPage();
    },
    initPage() {
      this.getAjax(
        "api/mahjong/list",
        { ...this.params, ...this.page },
        "get"
      ).then((res) => {
        this.tableData = res.data.list.records;
        this.total = res.data.list.total;
      });
    },
    add() {
      this.$refs.dialog.visible = true;
    },
    editData(item) {
      this.$refs.dialog.getInfo(item);
    },
    deleteData(item) {
      this.$confirm("确定删除吗？").then(() => {
        this.getAjax("api/mahjong/del", { id: item.id }, "post").then((res) => {
          this.$message.success("删除成功");
          this.initPage();
        });
      });
    },
  },
  mounted() {
    this.initPage();
    this.power = localStorage.getItem("power");
    setInterval(() => {
      this.initPage();
    }, 5000);
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="less">
.tianfulove {
  width: 100%;
  height: 100%;
  display: flex;
  .codeBox {
    .code {
      width: 50px;
      height: 50px;
      float: left;
      img {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
  .leftBox {
    width: 10%;
    min-width: 130px;
    height: 100%;
    background: #545c64;
  }
  .rightBox {
    flex: 1;
    padding: 20px;
    .fenye {
      text-align: right;
      padding: 15px 10px;
    }
  }
}
</style>

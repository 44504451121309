<template>
  <div id="gm-searchBox" class="gm-searchBox">
    <div v-if="modeValue === '1'" class="oneLine">
      <el-form
        ref="form"
        :model="formData"
        :label-width="modeValue === '1' ? '' : '120px'"
        class="formBox"
        :inline="true"
      >
        <el-form-item
          v-for="(item, index) in formItems"
          :key="index"
          :label="item.label"
          :style="modeValue === '1' ? 'margin-left:10px;' : ''"
        >
          <component
            :is="item.type"
            :ref="`${item.fieldName}`"
            v-model="formData[item.fieldName]"
            v-bind="{ clearable: true, ...item.attrs }"
            @change="changeSelect($event, item)"
          >
            <template v-if="item.type === 'el-select'">
              <el-option
                v-for="x in item.data"
                :key="x.value"
                :label="x.label"
                :value="x.value"
              />
            </template>
          </component>
        </el-form-item>
      </el-form>
      <div v-if="showBtn" class="btnBox">
        <el-button size="small" type="warning" @click="searchForm()">
          <i class="el-icon-search" />查询
        </el-button>
        <el-button size="small" type="primary" @click="resetForm()">
          <i class="el-icon-paperclip" />清除
        </el-button>
      </div>
    </div>
    <div v-if="modeValue === '2'" class="multiline" :style="'width:' + width">
      <div class="formBox">
        <el-form
          ref="form"
          :model="formData"
          label-width="120px"
          class="formBox"
          :inline="true"
        >
          <el-form-item
            v-for="(item, index) in formItems"
            :key="index"
            :label="item.label"
            :span="spanNum"
          >
            <component
              :is="item.type"
              :ref="`${item.fieldName}`"
              v-model="formData[item.fieldName]"
              v-bind="{ clearable: true, ...item.attrs }"
              style="min-width: 200px"
              @change="changeSelect($event, item)"
            >
              <template v-if="item.type === 'el-select'">
                <el-option
                  v-for="x in item.data"
                  :key="x.value"
                  :label="x.label"
                  :value="x.value"
                />
              </template>
            </component>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="showBtn" class="btnBox">
        <el-button type="warning" size="small" @click="searchForm()">
          <i class="m_iconfont icon-sousuochaxun" />搜索
        </el-button>
        <el-button size="small" type="primary" @click="resetForm()">
          <i class="m_iconfont icon-eraser" />重置
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GmSearchBar",
  components: {},
  props: {
    showBtn: {
      // 是否显示搜索按钮
      type: Boolean,
      default: true,
    },
    lineNum: {
      // 每行显示多少列，仅支持多行模式
      type: String,
      default: "4",
    },
    mode: {
      // 搜索模式：1单行2多行
      type: String,
      default: "1",
    },
    formItems: {
      // 表单项
      type: Array,
      default: () => {
        return [];
      },
      // 示例
      //  formItems2: [
      //     {
      //       label: '检测类型：',
      //       type: 'el-select',
      //       fieldName: 'sex',
      //       linkage: {
      //         name: 'sex2',//联动组件名
      //         paramName: 'id',//联动参数名
      //       },
      //       api: listctype,
      //       //   data: [{ label: '男', value: 1 }, { label: '女', value: 2 }],
      //       attrs: {},
      //     },
      //     {
      //       label: '检测类型2：',
      //       type: 'el-select',
      //       fieldName: 'sex2',
      //       api: listJcBaseDevData,
      //       attrs: {},
      //     },
      //     {
      //       label: '数据名称：',
      //       type: 'el-input',
      //       fieldName: 'name',
      //       attrs: {
      //         placeholder: '请输入名称',
      //       },
      //     },
      //     {
      //       label: '检测日期：',
      //       type: 'el-date-picker',
      //       fieldName: 'mydate',
      //       attrs: {
      //         type: 'daterange',
      //         'range-separator': '至',
      //         'start-placeholder': '开始日期',
      //         'end-placeholder': '结束日期',
      //         'value-format': 'yyyy-MM-dd',
      //         format: 'yyyy-MM-dd',
      //       },
      //       render: opt => {//参数变形
      //         return {
      //           'startTime': opt[0],
      //           'endTime': opt[1],
      //         }
      //       },
      //     },
      //     {
      //       label: '自定义组件：',
      //       type: kilometerMaker,
      //       fieldName: 'sex3',
      //       attrs: {
      //         valueData: {
      //           start: '',
      //           end: '',
      //         },
      //       },
      //       render: opt => {
      //         return {
      //           start: opt['start'],
      //           end: opt['end'],
      //         }
      //       },
      //     },
      //   ]
    },
    changeSearch: {
      // 是否值改变后触发搜索
      type: Boolean,
      default: false,
    },
    width: {
      // 搜索框宽度
      type: String,
      default: "100%",
    },
  },
  data() {
    return {
      modeValue: "1",
      spanNum: 8, // 每个元素所占宽度
      formData: {},
    };
  },
  watch: {},
  created() {
    this.spanNum = 24 / parseInt(this.lineNum);
    this.initData();
  },
  mounted() {
    this.$nextTick(() => {
      this.initMode();
    });
    window.onresize = () => {
      this.initMode();
    };
  },
  methods: {
    initMode() {
      // 检测搜索框的行数
      const hei = document.getElementById("gm-searchBox").clientHeight;
      this.modeValue = hei < 100 ? "1" : "2";
    },
    initData(callback) {
      // 初始化数据
      let num = 0;
      let len = 0;
      this.formItems.forEach((o) => {
        // 检测异步函数数量
        if (o.api) {
          len++;
        }
      });
      this.formItems.forEach((e) => {
        // 如果传入接口，转化为数据流
        if (e.api) {
          e.api().then((res) => {
            this.$set(e, "data", res);
            let value;
            if (typeof e.defaultValue === "function") {
              value = e.defaultValue ? e.defaultValue(res) : "";
            } else {
              value = e.defaultValue;
            }

            this.$set(this.formData, e.fieldName, value);
            num++;
            if (num === len && callback) {
              callback();
            }
          });
        } else {
          this.$set(this.formData, e.fieldName, e.defaultValue || "");
        }
        // 参数变形
        if (e.render) {
          const data = e.render("");
          this.formData = Object.assign(this.formData, data);
        }
      });
    },
    changeSelect(opt, item) {
      // 判断是否设置了联动
      if (item.linkage) {
        const paramName = item.linkage.paramName;
        const params = {};
        params[paramName] = opt;
        this.$set(this.formData, item.linkage.name, "");
        if (!item.api) {
          throw new Error("必须要传入api");
        } else {
          this.formItems.forEach((e) => {
            if (e.fieldName === item.linkage.name) {
              e.api(params).then((res) => {
                this.$set(e, "data", res);
              });
            }
          });
        }
      }
      // 判断是否设置了立即刷新
      if (this.changeSearch) {
        this.searchForm();
      }
    },
    searchForm() {
      // 搜索
      this.formItems.forEach((e) => {
        // 参数变形
        if (e.render) {
          const data = e.render(this.formData[e.fieldName] || "");
          this.formData = Object.assign(this.formData, data);
        }
      });
      this._formatData();
    },
    resetForm() {
      // 重置表单
      for (const i in this.formItems) {
        // 自定义清除数据
        const refNode = this.$refs[this.formItems[i].fieldName][0];
        if (refNode.clearData) {
          refNode.clearData();
        }
      }
      this.initData(() => {
        this._formatData();
      });
      this._formatData();
    },
    _formatData() {
      // 参数转化
      const params = { ...this.formData };
      this.formItems.forEach((e) => {
        if (e.render) {
          delete params[e.fieldName];
        }
      });
      this.$emit("search", params);
    },
  },
};
</script>
<style lang="less" scoped>
.gm-searchBox {
  .el-form-item__label {
    white-space: nowrap;
  }
  .oneLine {
    overflow: hidden;
    display: flex;
    margin-left: -10px;
    .formBox {
      display: inline;
    }
    .btnBox {
      display: inline-block;
      .el-button {
        .m_iconfont {
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
  }
  .multiline {
    display: flex;
    margin: 0 auto;
    .formBox {
      flex: 1;
    }

    .btnBox {
      width: 100px;
      display: flex;
      align-items: center;
      flex-direction: column;
      .el-button {
        margin-left: 0;
        margin-top: 5px;
        display: flex;
        align-items: center;
        .m_iconfont {
          margin-right: 5px;
          font-size: 12px;
        }
      }
    }
  }

  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 10px;
  }
  .el-form-item {
    margin-bottom: 10px;
  }
}
</style>

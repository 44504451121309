<template>
  <div class="addressDialog">
    <el-dialog
      top="3%"
      width="50%"
      :title="dialogTitle"
      :visible.sync="visible"
      :close-on-click-modal="false"
      :before-close="closeEvent"
    >
      <el-form
        ref="dialogForm"
        :rules="rules"
        :model="form"
        label-width="120px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="麻将桌号：" prop="mahjongNumber">
              <el-input
                v-model="form.mahjongNumber"
                disabled
                placeholder="请输入麻将桌号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="归属地址：" prop="belong">
              <el-select
                v-model="form.belong"
                disabled
                placeholder="请选择归属地址"
                style="width: 100%"
              >
                <el-option
                  :label="item.label"
                  :value="item.value"
                  v-for="item in mahjongList"
                  :key="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="返积分：">
              <el-radio-group v-model="form.jifen">
                <el-radio :label="50">50积分</el-radio>
                <el-radio :label="100">100积分</el-radio>
                <el-radio :label="200">200积分</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeEvent()">取 消</el-button>
        <el-button type="primary" @click="submitData()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dialogTitle: "确认结算",
      mahjongList: [
        {
          label: "成都南站菜秘书麻将馆",
          value: "1",
        },
      ],
      form: {
        id: "",
        jifen: 100,
      },
      rules: {},
    };
  },
  methods: {
    submitData() {
      this.$refs.dialogForm.validate((valid) => {
        if (valid) {
          this.getAjax("api/mahjong/checkOut", { ...this.form }, "post").then(
            (res) => {
              this.$message.success("操作成功");
              this.closeEvent();
              this.$emit("search");
            }
          );
        }
      });
    },
    closeEvent() {
      this.visible = false;
      this.$refs.dialogForm.resetFields();
      this.form = {};
    },
    getInfo(item) {
      this.form = JSON.parse(JSON.stringify(item));
      this.form.jifen = 100;
      this.visible = true;
    },
  },
  mounted() {},
};
</script>

<style lang="less">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9 !important;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
